.img_p{
    display: flex;
    height: 60vh;
    gap: 2%;
}
.img_p > div {
    flex: 1;
    border-radius: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    transition: all .8s cubic-bezier(.25, .4, .45, 1.4);
}
.img_p > div:hover {
    flex: 5;
}